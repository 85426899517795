import React, {
  useState,
  useEffect,
  Fragment,
  ReactNode,
  SetStateAction,
  Dispatch,
  ComponentProps,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { styled, useTheme } from "@mui/system";
import { Box, Link, Theme, Typography } from "@mui/material";
import { FastForwardRounded as FastForwardIcon } from "@mui/icons-material";
import { Button } from "../styled";
import PulseLoader from "react-spinners/PulseLoader";
import OrderCompletionFlow from "../OrderCompletionFlow";
import OrderSummary from "../OrderCompletionFlow/OrderSummary";
import SaveOrder from "./SaveOrder";
import { useSubdomain } from "../../contexts/SubdomainContext";
import { getLocations, getUser } from "../../api";
import { Message, Order, Location, User } from "../../types";

interface ChatMessageProps {
  message: Message;
  showButton?: boolean;
  disableButton?: boolean;
  handleCallFunction?: () => void;
  formatContent?: boolean;
  children?: ReactNode;
}

export const ChatMessage = ({
  message,
  showButton,
  disableButton,
  handleCallFunction,
}: ChatMessageProps) => {
  const content = message.choices[0].message.content;
  const role = message.choices[0].message.role;
  const timestamp = new Date(message.created * 1000).toLocaleTimeString(
    "en-US",
    { hour: "numeric", minute: "numeric", hour12: true }
  );

  if (!content) {
    return null;
  }

  const formattedContent = formatContent(content);

  return (
    <MessageBox role={role}>
      <MessageContent role={role}>{formattedContent}</MessageContent>
      <MessageFooter role={role} height={showButton ? 6 : 4}>
        <Timestamp>{timestamp}</Timestamp>
        {role === "assistant" && showButton && (
          <ButtonContainer justifyContent="flex-end" animated>
            <ConfirmButton
              variant="contained"
              disabled={disableButton}
              onClick={handleCallFunction}
            >
              Confirm
            </ConfirmButton>
          </ButtonContainer>
        )}
      </MessageFooter>
    </MessageBox>
  );
};

const formatContent = (content: string): ReactNode => {
  const splitContent = content.split(
    /(BaristaGPT|hello@baristagpt.com|\*\*[^*]+\*\*|\*[^*]+\*)/g
  );
  return splitContent.map((item, index) => {
    if (item === "BaristaGPT") {
      return (
        <Link
          key={index}
          color="text.primary"
          fontWeight={500}
          href="https://baristagpt.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          {item}
        </Link>
      );
    } else if (item === "hello@baristagpt.com") {
      return (
        <Link
          key={index}
          color="text.primary"
          fontWeight={500}
          href="mailto:hello@baristagpt.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          {item}
        </Link>
      );
    } else if (item.startsWith("**") && item.endsWith("**")) {
      return (
        <Typography key={index} component="span" fontWeight="bold">
          {item.slice(2, -2)}
        </Typography>
      );
    } else if (item.startsWith("*") && item.endsWith("*")) {
      return (
        <Typography key={index} component="span" fontStyle="italic">
          {item.slice(1, -1)}
        </Typography>
      );
    } else {
      return item;
    }
  });
};

export const TypingMessage = () => (
  <MessageBox role="assistant">
    <MessageContent role="assistant">
      <PulseLoader size={5} />
    </MessageContent>
    <MessageFooter role="assistant" height={1} />
  </MessageBox>
);

interface OrderCompletionMessageProps {
  order: Order;
  setOrder: Dispatch<SetStateAction<Order | undefined>>;
  showOrderCompletion: boolean;
  setShowOrderCompletion: Dispatch<SetStateAction<boolean>>;
  conversationId: string;
}

export const OrderCompletionMessage = ({
  order,
  setOrder,
  showOrderCompletion,
  setShowOrderCompletion,
  conversationId,
}: OrderCompletionMessageProps) => {
  const { user: authUser, loginWithPopup } = useAuth0();
  const { subdomain } = useSubdomain();
  const [user, setUser] = useState<User>();
  const [locations, setLocations] = useState<Location[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<Location>();

  useEffect(() => {
    const fetchLocations = async () => {
      getLocations(subdomain || "")
        .then((response) => {
          setLocations(response.data);
          setSelectedLocation(response.data[0]);
        })
        .catch((error) => console.error("Error fetching locations", error));
    };

    fetchLocations();
  }, [subdomain]);

  useEffect(() => {
    if (selectedLocation) {
      setOrder((prevOrder) => ({
        ...prevOrder,
        location_id: selectedLocation.id,
      }));
    }
  }, [selectedLocation, setOrder]);

  useEffect(() => {
    const fetchUser = async (userId: string) => {
      getUser(userId)
        .then((response) => setUser(response.data))
        .catch((error) => console.error("Error fetching user", error));
    };

    if (authUser) {
      fetchUser(authUser["sub"] || "");
    }
  }, [authUser]);

  return (
    <Fragment>
      <MessageBox role="assistant" minWidth={260}>
        <MessageContent role="assistant">
          <OrderSummary order={order} />
          {user ? (
            <SaveOrder order={order} user={user} />
          ) : (
            <Box display="flex" alignItems="center" padding={1} gap={1}>
              <FastForwardIcon color="primary" />
              <Typography variant="body2" fontWeight={600} color="primary">
                <Link
                  onClick={() => loginWithPopup()}
                  sx={{ cursor: "pointer" }}
                >
                  Sign up
                </Link>{" "}
                now to save your usual order!
              </Typography>
            </Box>
          )}
          {!showOrderCompletion && (
            <ButtonContainer>
              <Button
                variant="contained"
                onClick={() => setShowOrderCompletion(true)}
              >
                Complete order
              </Button>
            </ButtonContainer>
          )}
        </MessageContent>
        <Box mb={1} />
      </MessageBox>
      <MessageBox wide>
        {showOrderCompletion && (
          <MessageContent role="assistant">
            <OrderCompletionFlow
              user={user}
              locations={locations}
              selectedLocation={selectedLocation}
              setSelectedLocation={setSelectedLocation}
              order={order}
              conversationId={conversationId}
            />
          </MessageContent>
        )}
      </MessageBox>
    </Fragment>
  );
};

interface MessageBoxProps extends ComponentProps<typeof Box> {
  role?: string;
  wide?: boolean;
}

const MessageBox = styled(({ role, wide, ...props }: MessageBoxProps) => (
  <Box {...props} />
))(({ role, wide }) => {
  const theme: Theme = useTheme();
  return {
    fontFamily: theme.typography.body1.fontFamily,
    alignSelf: role === "user" ? "flex-end" : "flex-start",
    maxWidth: "50%",
    width: wide ? "40%" : "auto",

    [theme.breakpoints.down("sm")]: {
      width: wide ? "90%" : "auto",
      maxWidth: wide ? "90%" : "70%",
    },
  };
});

const MessageContent = styled(Box)(({ theme, role }) => ({
  color:
    role === "user" ? theme.palette.common.white : theme.palette.common.black,
  backgroundColor:
    role === "user" ? theme.palette.primary.main : theme.palette.light.main,
  padding: theme.spacing(1.25),
  borderRadius: theme.spacing(1.25),
  whiteSpace: "pre-line",
  lineHeight: 1.5,
}));

const Timestamp = styled("span")({
  fontSize: "12px",
});

const ConfirmButton = styled(Button)({
  fontSize: "14px",
  justifyContent: "center",
});

interface MessageFooterProps extends ComponentProps<typeof Box> {
  role: string;
  height?: number;
}

const MessageFooter = styled(
  ({ role, height, ...props }: MessageFooterProps) => <Box {...props} />
)(({ theme, role, height = 4 }) => ({
  display: "flex",
  height: theme.spacing(height),
  justifyContent: role === "user" ? "flex-end" : "space-between",
  alignItems: "center",
  marginBottom: theme.spacing(0.5),
}));

interface ButtonContainerProps {
  justifyContent?: string;
  animated?: boolean;
  children?: ReactNode;
}

const ButtonContainer = styled(
  ({ justifyContent, animated, ...otherProps }: ButtonContainerProps) => (
    <Box {...otherProps} />
  )
)(({ theme, justifyContent, animated }) => ({
  display: "flex",
  justifyContent: justifyContent || "center",
  alignItems: "center",
  padding: `${theme.spacing(1.25)} 0`,
  animation: animated ? "fade-in 0.5s" : "none",
}));
